import { useMutation } from '@tanstack/react-query';
import { login } from '@/shared/api/auth/login';

export const useLogin = () => {
  return useMutation({
    mutationFn: login,
    mutationKey: getUseLoginMutationKey(),
  });
};

export const getUseLoginMutationKey = () => ['login'];
