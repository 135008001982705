'use client';
import { FC, ReactNode } from 'react';
import {
  isServer,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import {
  makeMutationCacheConfig,
  makeQueryCacheConfig,
  MakeQueryClient,
  makeQueryClient,
} from '@/shared/api/queryClient';
import { useTranslations } from 'next-intl';

let browserQueryClient: QueryClient | undefined = undefined;

function getQueryClient(params: MakeQueryClient) {
  if (isServer) {
    return makeQueryClient(params);
  } else {
    if (!browserQueryClient) browserQueryClient = makeQueryClient(params);

    /* Обновляем конфиги queryCache и mutationCache */
    browserQueryClient.getQueryCache().config = makeQueryCacheConfig(params);
    browserQueryClient.getMutationCache().config =
      makeMutationCacheConfig(params);

    return browserQueryClient;
  }
}

interface Props {
  children: ReactNode;
}

export const CustomQueryClientProvider: FC<Props> = ({ children }) => {
  const errorsTranslations = useTranslations('errors');

  const queryClient = getQueryClient({ errorsTranslations });

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
