'use client';
import { FC, useEffect } from 'react';
import { useAuth } from '@/shared/providers/AuthProvider';
import { useSearchParams } from 'next/navigation';
import useGetMe from '@/shared/hooks/useGetMe';
import { useLogin } from '@/shared/hooks/useLogin';
import {
  useLocalizedPathname,
  useLocalizedRouter,
} from '@/shared/i18n/routing';
import AuthPopup from '@/features/AuthPopup/AuthPopup';
import { useLocale } from 'next-intl';

interface Props {}

const Auth: FC<Props> = ({}) => {
  const router = useLocalizedRouter();
  const locale = useLocale();
  const pathname = useLocalizedPathname();
  const params = useSearchParams();

  const { setToken, codeVerifier, redirectUrl, isPopupOpen, closePopup } =
    useAuth();

  const { mutate } = useLogin();

  useGetMe({ lang: locale });

  useEffect(() => {
    const code = params.get('code');

    if (code && codeVerifier && redirectUrl) {
      mutate(
        {
          code,
          code_verifier: codeVerifier,
          redirect_url: redirectUrl,
          lang: locale,
        },
        {
          onSuccess: (data) => {
            const token = data?.data.token;

            if (token) {
              setToken(token);
              // Чтобы параметр код не висел
              router.replace(pathname, { scroll: true });
            }
          },
        }
      );
    }
  }, [params, codeVerifier]);

  return <AuthPopup onClose={closePopup} isOpen={isPopupOpen} />;
};

export default Auth;
